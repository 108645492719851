import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';
import css from './BlockDefault.module.css';
import { useSelector } from 'react-redux';
import { NamedLink } from '../../../../components';
import { getUserType } from '../../../../util/helper';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const FieldMedia = props => {
  const { className, media, sizes, options } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

const BlockDefault = props => {
  const {
    blockId,
    sectionId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
  } = props; 

  const { isAuthenticated } = useSelector(state => state.auth);
  const { currentUser } = useSelector(state => state.user);
  const userType = getUserType(currentUser);
  const history = useHistory();
  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);
 
  const dynamicAthleteRoutesFi = content => {
    switch (content) {
      case 'Logonäkyvyys':
        return history.push('brandSearch?pub_brandSponsorshipType=logo_visibility');
      case 'Sosiaalinen media':
        return history.push('brandSearch?pub_brandSponsorshipType=social_media');
      case 'Esiintyminen':
        return history.push('brandSearch?pub_brandSponsorshipType=appearance');
      case 'Brändilähettiläs':
        return history.push('brandSearch?pub_brandSponsorshipType=brand_ambassador');
      case 'Tapahtuma':
        return history.push('brandSearch');
      case content:
        return history.push(callToAction?.href)
    }
  };

  const dynamicAthleteRoutesEN = content => {
    switch (content) {
      case 'Logo visibility':
        return history.push('brandSearch?pub_brandSponsorshipType=logo_visibility');
      case 'Social media':
        return history.push('brandSearch?pub_brandSponsorshipType=social_media');
      case 'Appearance':
        return history.push('brandSearch?pub_brandSponsorshipType=appearance');
      case 'Brand ambassador':
        return history.push('brandSearch?pub_brandSponsorshipType=brand_ambassador');
      case 'Event':
        return history.push('brandSearch');
        case content:
          return history.push(callToAction.href)
    }
  };

  const dynamicBrandRoutesEN = content => {
    switch (content) {
      case 'Logo visibility':
        return history.push('athleteSearch?pub_sponsorshipType=has_all%3Alogo_visibility');
      case 'Social media':
        return history.push('athleteSearch?pub_sponsorshipType=has_all%3Asocial_media');
      case 'Appearance':
        return history.push('athleteSearch?pub_sponsorshipType=has_all%3Aappearance');
      case 'Brand ambassador':
        return history.push('athleteSearch?pub_sponsorshipType=has_all%3Abrand_ambassador');
      case 'Event':
        return history.push('athleteSearch?pub_sponsorshipType=has_all%3Aevents');
        case content:
          return history.push(callToAction?.href)
    }
  };

  const dynamicBrandRoutesFI = content => {
    switch (content) {
      case 'Logonäkyvyys':
        return history.push('athleteSearch?pub_sponsorshipType=has_all%3Alogo_visibility');
      case 'Sosiaalinen media':
        return history.push('athleteSearch?pub_sponsorshipType=has_all%3Asocial_media');
      case 'Esiintyminen':
        return history.push('athleteSearch?pub_sponsorshipType=has_all%3Aappearance');
      case 'Brändilähettiläs':
        return history.push('athleteSearch?pub_sponsorshipType=has_all%3Abrand_ambassador');
      case 'Tapahtuma':
        return history.push('athleteSearch?pub_sponsorshipType=has_all%3Aevents');
      case content:
        return history.push(callToAction?.href)
    }
  }; 
  const language = typeof window != 'undefined' && localStorage.getItem('language');
  const dynamicAthleteRoutes =language === 'Finnish' ? dynamicAthleteRoutesFi : dynamicAthleteRoutesEN;
  const dynamicBrandRoutes = language === 'Finnish' ? dynamicBrandRoutesFI : dynamicBrandRoutesEN;

  return (
    <BlockContainer id={blockId} className={classes}>
      <FieldMedia
        media={media}
        sizes={responsiveImageSizes}
        className={mediaClassName}
        options={options}
      />
      {hasTextComponentFields ? (
        <div className={classNames(textClassName, css.text)}>
          <Field data={title} options={options} />
          <Field data={text} options={options} />
          {!isAuthenticated ? (
            <>
              <Field data={callToAction} className={ctaButtonClass} options={options} />
            </>
          ) : userType === 'athlete' &&
            blockId != 'brand-section-block-1' &&
            blockId != 'brand-section-block-2' &&
            blockId != 'brand-section-block-3' &&
            blockId != 'brand-section-block-4' &&
            blockId != 'athlete-blog-section-block-1' &&
            blockId != 'athlete-blog-section-block-2' &&
            blockId != 'athlete-blog-section-block-3' &&
            blockId != 'athlete-section-block-1' &&
            blockId != 'athlete-section-block-2' &&
            blockId != 'athlete-section-block-3' &&
            blockId != 'athlete-section-block-4' &&
            blockId != 'about-us-block-1' &&
            blockId != 'section-1-block-1' &&
            blockId != 'privacy-policy-content' &&
            blockId != 'faq-content' ? (
            <div className={css.listingButton}>
              {sectionId === 'blog'  ? (
                <button onClick={()=>history.push(callToAction?.href)}><FormattedMessage id="BlockDefault.lueLisää" /></button>
              ) : (
                <button onClick={() => dynamicAthleteRoutes(title?.content)}>
                  {callToAction?.href ? (
                    <FormattedMessage id="BlockDefault.lueLisääCta" />
                  ) : (
                    <FormattedMessage id="BlockDefault.seeListing" />
                  )}
                </button>
              )}
            </div>
          ) : blockId != 'about-us-block-1' &&
            blockId != 'brand-section-block-1' &&
            blockId != 'brand-section-block-2' &&
            blockId != 'brand-section-block-3' &&
            blockId != 'brand-section-block-4' &&
            blockId != 'athlete-blog-section-block-1' &&
            blockId != 'athlete-blog-section-block-2' &&
            blockId != 'athlete-blog-section-block-3' &&
            blockId != 'athlete-section-block-1' &&
            blockId != 'section-1-block-1' &&
            blockId != 'athlete-section-block-2' &&
            blockId != 'athlete-section-block-3' &&
            blockId != 'athlete-section-block-4' &&
            blockId != 'privacy-policy-content' &&
            blockId != 'faq-content' ? (
            <div className={css.listingButton}>
             {sectionId === 'blog' ? (
                <button  onClick={()=>history.push(callToAction?.href)}><FormattedMessage id="BlockDefault.lueLisää" /></button>
              ) : (
                <button onClick={() => dynamicAthleteRoutes(title?.content)}>
                  {callToAction?.href ? (
                    <FormattedMessage id="BlockDefault.lueLisääCta" />
                  ) : (
                    <FormattedMessage id="BlockDefault.seeListing" />
                  )}
                </button>
              )}
            </div>
          ) : null}
        </div>
      ) : null}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockDefault.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockDefault.propTypes = {
  blockId: string.isRequired,
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default BlockDefault;
