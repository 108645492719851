import React from 'react';
import { arrayOf, bool, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionColumns.module.css';
import { useSelector } from 'react-redux';
import { FormattedMessage } from '../../../../util/reactIntl';
import createCampaignImage from '../../../../assets/images/create-campaign.png';
import exploreAthleteImage from '../../../../assets/images/explore-athlete.png';
import sponserDealImage from '../../../../assets/images/sponser-image.png';
import { NamedLink } from '../../../../components';

// The number of columns (numColumns) affects styling and responsive images
const COLUMN_CONFIG = [
  { css: css.oneColumn, responsiveImageSizes: '(max-width: 767px) 100vw, 1200px' },
  { css: css.twoColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 600px' },
  { css: css.threeColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 400px' },
  { css: css.fourColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 265px' },
];
const getIndex = numColumns => numColumns - 1;
const getColumnCSS = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.css : COLUMN_CONFIG[0].css;
};
const getResponsiveImageSizes = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.responsiveImageSizes : COLUMN_CONFIG[0].responsiveImageSizes;
};

// Section component that's able to show blocks in multiple different columns (defined by "numColumns" prop)
const SectionColumns = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    numColumns,
    title,
    description,
    appearance,
    callToAction,
    blocks,
    isInsideContainer,
    options,
  } = props;

  const { isAuthenticated } = useSelector(state => state.auth);
  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const hasBlocks = blocks?.length > 0;

  const language = typeof window !== 'undefined' && localStorage.getItem('language');
  const dynamicBrandRoute = language ==="Finnish" ? "brandi" : "describing-brand";
  const dynamicAthleteRoute = language ==="Finnish" ? "urheilija" : "describing-athlete";

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <Field data={title} className={defaultClasses.title} options={fieldOptions} />
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
        </header>
      ) : null}
      {sectionId == "section-2" && !isAuthenticated ?
        <div className={css.verifyWrapper}>
          <div className={css.athleteSection}>
            <div className={css.brandTop}>
              <div className={css.brandHeading}><FormattedMessage id="SectionColumn.areYouAthleteOrTeam" /></div>
              <div className={css.brandDescription}><FormattedMessage id="SectionColumn.athleteOrTeamDescription" /> </div>
              <NamedLink name="SignupPage" className={css.signUpButton}>
                <FormattedMessage id="SectionColumn.signUp" />
              </NamedLink>

            </div>
          </div>
          <div className={css.brandSection}>
            <div className={css.brandTop}>
              <div className={css.brandHeading}><FormattedMessage id="SectionColumn.areYouBrand" /></div>
              <div className={css.brandDescription}><FormattedMessage id="SectionColumn.brandDescription" /></div>
              <NamedLink name="SignupPage" className={css.signUpButton}>
                <FormattedMessage id="SectionColumn.signUp" />
              </NamedLink>
            </div>
          </div>
        </div> :
        sectionId == "section-4" ?
          <div className={css.workWrapper}>
            <div className={css.gridSection}>
              <div className={css.firstSection}>
                <div className={css.imageSection}>
                  <img src={createCampaignImage} />
                </div>
                <div className={css.leftLayout}>
                  <div className={css.workHeading}><FormattedMessage id="SectionColumn.createCampaign" /></div>
                  <div className={css.workSubHeading}><FormattedMessage id="SectionColumn.campaignSubHeading" /></div>
                  <NamedLink className={css.topButton} name="CampaignDescriptionPage">
                    <FormattedMessage id="SectionColumn.learnMore" />
                  </NamedLink>
                </div>
              </div>
              
              <div className={css.secondSection}>
                <div className={css.leftLayout}>
                  <div className={css.workHeading}><FormattedMessage id="SectionColumn.exploreAthletes" /></div>
                  <div className={css.workSubHeading}><FormattedMessage id="SectionColumn.signUpSubHeading" /></div>
                  <a className={css.topButton} href={language === 'Finnish' ? `${process.env.REACT_APP_MARKETPLACE_ROOT_URL}/${dynamicBrandRoute}` :`${process.env.REACT_APP_MARKETPLACE_ROOT_URL}/en/${dynamicBrandRoute}`}>
                    <FormattedMessage id="SectionColumn.sectionButtonAthlete" />
                  </a>
                  
                </div>
                <div className={css.imageSection}>
                  <img src={exploreAthleteImage} />
                </div>
              </div>
              <div className={css.firstSection}>
                <div className={css.imageSection}>
                  <img src={sponserDealImage} />
                </div>
                <div className={css.leftLayout}>
                  <div className={css.workHeading}><FormattedMessage id="SectionColumn.sponsorDeals" /></div>
                  <div className={css.workSubHeading}><FormattedMessage id="SectionColumn.sponsorDealSubHeading" /></div>
                  <a className={css.topButton} href={language === 'Finnish' ? `${process.env.REACT_APP_MARKETPLACE_ROOT_URL}/${dynamicAthleteRoute}` :`${process.env.REACT_APP_MARKETPLACE_ROOT_URL}/en/${dynamicAthleteRoute}`}>
                    <FormattedMessage id="SectionColumn.sectionButtonForBrand" />
                  </a>

                </div>
              </div>
              {/* <div className={css.rightLayout}>
                <div className={css.workHeading}>Explore athletes</div>
                <div className={css.workSubHeading}>Sign up and create an account, it's free. Looking for an athlete or maybe a team to sponsor? You'll decide what is the best pick for your brand.</div>
                {!isAuthenticated ?
                  <button>Sign up</button> : null
                }
              </div>
              <div className={css.leftLayout}>
                <div className={css.workHeading}>Sponsor deals made easy</div>
                <div className={css.workSubHeading}>Send proposals to brands that interest you or receive offers from companies directly. Sponsor search, negotiation and signing the contract is easy with us. </div>
                {!isAuthenticated ?
                  <button>Sign up</button> : null
                }
              </div> */}
            </div>
          </div>
          :
          hasBlocks ? (
            <div
              className={classNames(defaultClasses.blockContainer, getColumnCSS(numColumns), {
                [css.noSidePaddings]: isInsideContainer,
              })}
            >
              <BlockBuilder
                ctaButtonClass={defaultClasses.ctaButton}
                blocks={blocks}
                sectionId={sectionId}
                responsiveImageSizes={getResponsiveImageSizes(numColumns)}
                options={options}
              />
            </div>
          ) : null}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionColumns.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  numColumns: 1,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionColumns.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  numColumns: number,
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionColumns;