export const sectionfi = [
  {
    description: {
      fieldType: 'paragraph',
      content:
        'Are you a brand or an athlete seeking sport sponsorship opportunities? Discover sbonssy, the innovative marketplace connecting you with sponsorship deals instantly.',
      // "content": "Oletko brändi tai urheilija, joka etsii urheilun sponsorointimahdollisuuksia? \nLöydä sbonssy, innovatiivinen markkinapaikka, joka yhdistää sinut sponsoroinnin\ntarjouksiin välittömästi."
    },
    numColumns: 2,
    callToAction: {
      fieldType: 'none',
      content: 'Kirjaudu',
      href: '',
    },
    sectionType: 'hero',
    sectionId: '',
    title: {
      fieldType: 'heading1',
      content: 'Link between \nbrands and athletes',
    },
    sectionName: 'Johdatus markkinoille',
    blocks: [],
    appearance: {
      fieldType: 'customAppearance',
      backgroundImage: {
        id: 'f3b0b4c4-37cc-5844-9500-d3cce7c9b775',
        type: 'imageAsset',
        attributes: {
          variants: {
            scaled800: {
              height: 533,
              width: 800,
              url:
                'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/f6/9843221111994f703781ac8d0d3be12ff53d4a?auto=format&fit=clip&h=800&w=800&s=b880d0ba2c0dfdca2b18226247cca8fe',
              name: 'scaled800',
            },
            scaled1200: {
              height: 800,
              width: 1200,
              url:
                'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/f6/9843221111994f703781ac8d0d3be12ff53d4a?auto=format&fit=clip&h=1200&w=1200&s=740b37a8ab6c33640ce0a28ef7390401',
              name: 'scaled1200',
            },
            scaled2400: {
              height: 1600,
              width: 2400,
              url:
                'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/f6/9843221111994f703781ac8d0d3be12ff53d4a?auto=format&fit=clip&h=2400&w=2400&s=2d6a7add46a2f21938493bf84b067b12',
              name: 'scaled2400',
            },
          },
          assetPath:
            '/content/pages/landing-page/section-background-018e5011-03bb-88f5-a8e3-05b3a57f29a8.jpg',
        },
      },
      textColor: 'white',
      backgroundColor: '#97b1c9',
      backgroundImageOverlay: {
        preset: 'none',
      },
    },
  },
  {
    title: {
      fieldType: 'heading2',
    },
    description: {
      fieldType: 'paragraph',
    },
    callToAction: {
      fieldType: 'none',
    },
    appearance: {
      fieldType: 'defaultAppearance',
    },
    sectionName: 'athlete team',
    sectionType: 'columns',
    numColumns: 2,
    blocks: [],
  },
  {
    description: {
      fieldType: 'paragraph',
      content:
        '  Discover exciting opportunities to enhance your sports career or brand. Sign up and give a significant boost to your success in the sports industry.',
    },
    numColumns: 3,
    callToAction: {
      fieldType: 'none',
    },
    sectionType: 'carousel',
    sectionId: '',
    title: {
      fieldType: 'heading2',
      content: 'Sponsorship opportunities',
    },
    sectionName: 'Suositellut paikat',
    blocks: [
      {
        media: {
          fieldType: 'image',
          image: {
            id: 'e09e2973-b20e-5522-a6f4-3901b03e87b1',
            type: 'imageAsset',
            attributes: {
              variants: {
                square400: {
                  height: 400,
                  width: 400,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/a4/a9cd540c4072603b007cb83ecf9c79fa41a409?auto=format&crop=edges&fit=crop&h=400&w=400&s=ebcf4117583f40809e4bd28368b23b20',
                  name: 'square400',
                },
                square800: {
                  height: 800,
                  width: 800,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/a4/a9cd540c4072603b007cb83ecf9c79fa41a409?auto=format&crop=edges&fit=crop&h=800&w=800&s=d07250b7c829511c0ccd2a9432c72ac8',
                  name: 'square800',
                },
                square1200: {
                  height: 1200,
                  width: 1200,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/a4/a9cd540c4072603b007cb83ecf9c79fa41a409?auto=format&crop=edges&fit=crop&h=1200&w=1200&s=aa68d3c4bafcb08b73683299ea1c0c93',
                  name: 'square1200',
                },
                square2400: {
                  height: 2400,
                  width: 2400,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/a4/a9cd540c4072603b007cb83ecf9c79fa41a409?auto=format&crop=edges&fit=crop&h=2400&w=2400&s=03feb4394fb383d852811e9c3640879d',
                  name: 'square2400',
                },
              },
              assetPath:
                '/content/pages/landing-page/block-media-018e5014-faea-827a-8ba6-2119d6860bcf.png',
            },
          },
          aspectRatio: '1/1',
          alt: 'Logo',
        },
        title: {
          fieldType: 'heading3',
          content: 'Logo visibility',
        },
        text: {
          fieldType: 'markdown',
          content: 'Brand logo on a jersey or equipment?\n\n\n\n[Find](/en/signup)',
        },
        callToAction: {
          fieldType: 'none',
          href: '',
        },
        blockType: 'defaultBlock',
        blockId: '',
        blockName: 'Tyyppi - Logo',
      },
      {
        media: {
          fieldType: 'image',
          image: {
            id: '32a44158-ee29-52d0-af45-3050d556eda8',
            type: 'imageAsset',
            attributes: {
              variants: {
                square400: {
                  height: 400,
                  width: 400,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/2b/3fcbbd6e997219982e833ee87b152fd0bfc6ee?auto=format&crop=edges&fit=crop&h=400&w=400&s=86696072f7354c238cd8507e253fe98e',
                  name: 'square400',
                },
                square800: {
                  height: 800,
                  width: 800,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/2b/3fcbbd6e997219982e833ee87b152fd0bfc6ee?auto=format&crop=edges&fit=crop&h=800&w=800&s=6b750e86e2dffe0bff41f5eaf0e3a57b',
                  name: 'square800',
                },
                square1200: {
                  height: 1200,
                  width: 1200,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/2b/3fcbbd6e997219982e833ee87b152fd0bfc6ee?auto=format&crop=edges&fit=crop&h=1200&w=1200&s=3488c94e35f3e8a8e9e589ca21141331',
                  name: 'square1200',
                },
                square2400: {
                  height: 2400,
                  width: 2400,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/2b/3fcbbd6e997219982e833ee87b152fd0bfc6ee?auto=format&crop=edges&fit=crop&h=2400&w=2400&s=dde35c5f1d161d669502ae7ebfa15238',
                  name: 'square2400',
                },
              },
              assetPath:
                '/content/pages/landing-page/block-media-018e5018-5374-8c2a-b441-cc7af4bf4034.png',
            },
          },
          aspectRatio: '1/1',
          alt: 'Social media',
        },
        title: {
          fieldType: 'heading3',
          content: 'Social media',
        },
        text: {
          fieldType: 'markdown',
          content:
            'Authentic user-generated content to increase brand awareness!\n\n[Find](/en/signup)',
        },
        callToAction: {
          fieldType: 'none',
        },
        blockType: 'defaultBlock',
        blockId: '',
        blockName: 'Tyyppi - Sosiaalinen',
      },
      {
        media: {
          fieldType: 'image',
          image: {
            id: '46e2f66f-5b99-5ea1-b9c4-a732ade6d8f3',
            type: 'imageAsset',
            attributes: {
              variants: {
                square400: {
                  height: 400,
                  width: 400,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/ea/e3616419a64f9cc815934ae7c80fd35cdc7e52?auto=format&crop=edges&fit=crop&h=400&w=400&s=f6ef5f1ad4a6ad0dfcc0ae66eed26409',
                  name: 'square400',
                },
                square800: {
                  height: 800,
                  width: 800,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/ea/e3616419a64f9cc815934ae7c80fd35cdc7e52?auto=format&crop=edges&fit=crop&h=800&w=800&s=ddb643e716fd995e9a58188a81adbcdf',
                  name: 'square800',
                },
                square1200: {
                  height: 1200,
                  width: 1200,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/ea/e3616419a64f9cc815934ae7c80fd35cdc7e52?auto=format&crop=edges&fit=crop&h=1200&w=1200&s=0b367144449f67e544fef57a23b5a790',
                  name: 'square1200',
                },
                square2400: {
                  height: 2400,
                  width: 2400,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/ea/e3616419a64f9cc815934ae7c80fd35cdc7e52?auto=format&crop=edges&fit=crop&h=2400&w=2400&s=c043740ff09b34e31876484f45c8137d',
                  name: 'square2400',
                },
              },
              assetPath:
                '/content/pages/landing-page/block-media-018e5018-71fb-88db-94cd-f81b2f5e68f6.png',
            },
          },
          alt: 'Appearance',
          aspectRatio: '1/1',
        },
        title: {
          fieldType: 'heading3',
          content: 'Appearance',
        },
        text: {
          fieldType: 'markdown',
          content:
            'Invite athlete to a corporate event or organize photo shoots to highlight your business.\n\n[Find](/en/signup)',
        },
        callToAction: {
          fieldType: 'none',
        },
        blockType: 'defaultBlock',
        blockId: '',
        blockName: 'Tyyppi - Ulkonäkö',
      },
      {
        media: {
          fieldType: 'image',
          image: {
            id: 'f08cf6e4-a8f2-5be3-8307-cd4fa17ac6d6',
            type: 'imageAsset',
            attributes: {
              variants: {
                square400: {
                  height: 400,
                  width: 400,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/40/929b036cfa0bb795d0ed700bf87575b9d341eb?auto=format&crop=edges&fit=crop&h=400&w=400&s=64011d03fb7ffb9261bfeb4d1ee4eba8',
                  name: 'square400',
                },
                square800: {
                  height: 800,
                  width: 800,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/40/929b036cfa0bb795d0ed700bf87575b9d341eb?auto=format&crop=edges&fit=crop&h=800&w=800&s=5934ac8ea262739a1db81774de95810e',
                  name: 'square800',
                },
                square1200: {
                  height: 1200,
                  width: 1200,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/40/929b036cfa0bb795d0ed700bf87575b9d341eb?auto=format&crop=edges&fit=crop&h=1200&w=1200&s=e6665bf2b370742c9548c2de5cd2db20',
                  name: 'square1200',
                },
                square2400: {
                  height: 2400,
                  width: 2400,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/40/929b036cfa0bb795d0ed700bf87575b9d341eb?auto=format&crop=edges&fit=crop&h=2400&w=2400&s=52a0c669fbc24a61031ecf7722350bb8',
                  name: 'square2400',
                },
              },
              assetPath:
                '/content/pages/landing-page/block-media-018e5018-9a17-8455-97fc-f7e95287d5eb.png',
            },
          },
          aspectRatio: '1/1',
          alt: 'Brand ambassador',
        },
        title: {
          fieldType: 'heading3',
          content: 'Brand ambassador',
        },
        text: {
          fieldType: 'markdown',
          content:
            'Build your brand with long-term and multi-channel partnerships.\n\n[Find](/en/signup)',
        },
        callToAction: {
          fieldType: 'none',
        },
        blockType: 'defaultBlock',
        blockId: '',
        blockName: 'Tyyppi - Brand Ambassador',
      },
      {
        media: {
          fieldType: 'image',
          image: {
            id: '6a4b882f-8322-5eda-b98b-b4fde1bd2947',
            type: 'imageAsset',
            attributes: {
              variants: {
                square400: {
                  height: 400,
                  width: 400,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/93/63b4c4330777052f754180228dd49f85f2106c?auto=format&crop=edges&fit=crop&h=400&w=400&s=12fb0c38ed09e5829cc41b51d3aa89f0',
                  name: 'square400',
                },
                square800: {
                  height: 800,
                  width: 800,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/93/63b4c4330777052f754180228dd49f85f2106c?auto=format&crop=edges&fit=crop&h=800&w=800&s=37916ce96270704279e79ce0c86c7f3d',
                  name: 'square800',
                },
                square1200: {
                  height: 1200,
                  width: 1200,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/93/63b4c4330777052f754180228dd49f85f2106c?auto=format&crop=edges&fit=crop&h=1200&w=1200&s=7032adff46772683ef046f075cc86a0f',
                  name: 'square1200',
                },
                square2400: {
                  height: 2400,
                  width: 2400,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/93/63b4c4330777052f754180228dd49f85f2106c?auto=format&crop=edges&fit=crop&h=2400&w=2400&s=7f7c8190c247a0e7ab52a9c729ef02a9',
                  name: 'square2400',
                },
              },
              assetPath:
                '/content/pages/landing-page/block-media-018e5019-21c8-8ec2-8891-159fb7c65746.png',
            },
          },
          aspectRatio: '1/1',
          alt: 'Event',
        },
        title: {
          fieldType: 'heading3',
          content: 'Event',
        },
        text: {
          fieldType: 'markdown',
          content:
            'Find an event and reach your target audience effectively.\n\n[Find](/en/signup)',
        },
        callToAction: {
          fieldType: 'none',
        },
        blockType: 'defaultBlock',
        blockName: 'Tyypillinen Event',
      },
    ],
    appearance: {
      fieldType: 'defaultAppearance',
    },
  },
  {
    description: {
      fieldType: 'paragraph',
      content:
        //'Alustamme tarjoaa helpon tavan löytää kumppanuuksia ja allekirjoittaa urheilusponsorointisopimuksia. \nUskomme, että parhaat tulokset saavutetaan, kun resurssit kohdistetaan\nydinosaaminen. Joten anna meidän auttaa sinua.',
        'Our platform offers an easy way finding partnerships and signing sports sponsorship deals. \nWe believe that the best results will be achieved when resources are focused core \ncompetences. So let us help you.',
    },
    numColumns: 3,
    callToAction: {
      fieldType: 'none',
      href: '/s',
      content: 'Testata',
    },
    sectionType: 'columns',
    sectionId: '',
    title: {
      fieldType: 'heading2',
      content: 'How sbonssy works',
    },
    sectionName: 'Kuinka se toimii',
    blocks: [
      {
        media: {
          fieldType: 'none',
        },
        title: {
          fieldType: 'heading3',
          content: 'Create campaign',
        },
        text: {
          fieldType: 'markdown',
          content:
            'Rakenna itsellesi\nbrändille sopiva kampanja ja hanki ehdotuksia\nsuoraan urheilijoilta',
        },
        callToAction: {
          fieldType: 'none',
          content: 'Kirjaudu',
          href: '/en/signup',
        },
        blockType: 'defaultBlock',
        blockId: '',
        blockName: 'Uusi kampanja',
      },
      {
        media: {
          fieldType: 'none',
        },
        title: {
          fieldType: 'heading3',
          content: 'Tutustu urheilijoihin',
        },
        text: {
          fieldType: 'markdown',
          content:
            'Rekisteröidy ja luo tili, se on ilmaista.\nEtsitkö urheilijaa tai kenties joukkuetta\ntukea? Sinä päätät, mikä se on\nparas valinta brändillesi.',
        },
        callToAction: {
          fieldType: 'none',
          content: 'Kirjaudu',
          href: '/en/signup',
        },
        blockType: 'defaultBlock',
        blockId: '',
        blockName: 'Ota selvää urheilijoiden tarjoamista sponsorointimahdollisuuksista',
      },
      {
        media: {
          fieldType: 'none',
        },
        title: {
          fieldType: 'heading3',
          content: 'Sponsorointi on tehty helpoksi',
        },
        text: {
          fieldType: 'markdown',
          content:
            'Lähetä ehdotuksia sinua kiinnostaville brändeille tai vastaanota tarjouksia suoraan yrityksiltä. Sponsorin etsiminen, neuvotteleminen ja sopimuksen allekirjoittaminen on kanssamme helppoa.',
        },
        callToAction: {
          fieldType: 'none',
          content: 'Kirjaudu',
          href: '/en/signup',
        },
        blockType: 'defaultBlock',
        blockName: 'Sponsorointi on tehty helpoksi',
      },
    ],
    appearance: {
      fieldType: 'defaultAppearance',
    },
  },
  {
    description: {
      fieldType: 'paragraph',
    },
    numColumns: 3,
    // "callToAction": {
    //     "fieldType": "none"
    // },
    sectionType: 'columns',
    sectionId: 'blog',
    title: {
      fieldType: 'heading2',
      content: 'Blogit',
    },
    sectionName: 'Blogi',
    blocks: [

      {
        media: {
          fieldType: 'image',
          image: {
            id: 'b0add141-68fa-5c3e-9f42-52bc8c3a4443',
            type: 'imageAsset',
            attributes: {
              variants: {
                square400: {
                  height: 400,
                  width: 400,
                  url: "https://sharetribe-assets.imgix.net/666a959e-c7e6-49da-b986-37d4681b6574/raw/b4/720bc24169dbec286d7e28a2b62168cf582a53?auto=format&crop=edges&fit=crop&h=400&w=400&s=3ca4e41bc1c1a1a6bd188577d0760724"

                },
                square800: {
                  height: 800,
                  width: 800,
                  url:
                    "https://sharetribe-assets.imgix.net/666a959e-c7e6-49da-b986-37d4681b6574/raw/b4/720bc24169dbec286d7e28a2b62168cf582a53?auto=format&crop=edges&fit=crop&h=800&w=800&s=835b93e920f67385149f4f7f0cb21418",
                  name: 'square800',
                },
                square1200: {
                  height: 1200,
                  width: 1200,
                  url:
                    "https://sharetribe-assets.imgix.net/666a959e-c7e6-49da-b986-37d4681b6574/raw/b4/720bc24169dbec286d7e28a2b62168cf582a53?auto=format&crop=edges&fit=crop&h=1200&w=1200&s=7fe1a216bf6fda19fc34a843ecf14bd8",
                  name: 'square1200',
                },
                square2400: {
                  height: 2400,
                  width: 2400,
                  url:
                    "https://sharetribe-assets.imgix.net/666a959e-c7e6-49da-b986-37d4681b6574/raw/b4/720bc24169dbec286d7e28a2b62168cf582a53?auto=format&crop=edges&fit=crop&h=2400&w=2400&s=a7576141dddd33e2acd3b855a747a4ac",
                },
              },
              assetPath:
                "/content/pages/landing-page-fi/block-media-0191290f-98ad-851b-838e-7e597dc06456.jpg",
            },
          },
          aspectRatio: '1/1',
          alt: 'Sport',
        },
        title: {
          fieldType: 'heading3',
          content: 'Haastattelu:\n\nMika Kojonkoski',
        },
        text: {
          fieldType: 'markdown',
          content:
            'Kysyimme valmentajalegendalta mitä mietteitä herättää suomalaisen huippu-urheilun ja sponsoroinnin tulevaisuus.',
        },
        callToAction: {
          fieldType: 'internalButtonLink',
          content: 'Lue lisää',
          href: '/en/blogi-mika-kojonkoski',
        },
        blockType: 'defaultBlock',
        blockName: 'toinen blogi',
      },

      {
        media: {
          fieldType: 'image',
          image: {
            id: 'b0add141-68fa-5c3e-9f42-52bc8c3a4443',
            type: 'imageAsset',
            attributes: {
              variants: {
                square400: {
                  height: 400,
                  width: 400,
                  url:
                    'https://sharetribe-assets.imgix.net/666a959e-c7e6-49da-b986-37d4681b6574/raw/6f/7c70c8f9188013b33ff5f3a4e6fee99b4be4ad?auto=format&crop=edges&fit=crop&h=400&w=400&s=ec58268903d67e7a38b569267e90b01c',
                  name: 'square400',
                },
                square800: {
                  height: 800,
                  width: 800,
                  url:
                    "https://sharetribe-assets.imgix.net/666a959e-c7e6-49da-b986-37d4681b6574/raw/6f/7c70c8f9188013b33ff5f3a4e6fee99b4be4ad?auto=format&crop=edges&fit=crop&h=800&w=800&s=f18a05351f1d96be54c014abbd31fa5f",
                  name: 'square800',
                },
                square1200: {
                  height: 1200,
                  width: 1200,
                  url:
                    "https://sharetribe-assets.imgix.net/666a959e-c7e6-49da-b986-37d4681b6574/raw/6f/7c70c8f9188013b33ff5f3a4e6fee99b4be4ad?auto=format&crop=edges&fit=crop&h=1200&w=1200&s=45143461ce66ba055a13a56d6ce286f7",
                  name: 'square1200',
                },
                square2400: {
                  height: 2400,
                  width: 2400,
                  url:
                    "https://sharetribe-assets.imgix.net/666a959e-c7e6-49da-b986-37d4681b6574/raw/6f/7c70c8f9188013b33ff5f3a4e6fee99b4be4ad?auto=format&crop=edges&fit=crop&h=2400&w=2400&s=769f95d8b4ce719f6e7092d4f20522f9",
                  name: 'square2400',
                },
              },
              assetPath:
                "/content/pages/landing-page-fi/block-media-0190fa14-cd94-8a6b-90b3-3f8baa3a4b22.jpg",
            },
          },
          aspectRatio: '1/1',
          alt: 'Sport',
        },
        title: {
          fieldType: 'heading3',
          content: 'Monikanavaista markkinointia',
        },
        text: {
          fieldType: 'markdown',
          content:
            'Urheilu on tehokas markkinoinnin kanava, jonka avulla yritykset voi rakentaa brändin tunnettuutta, kasvattaa myyntiä sekä tavoittaa sitoutunutta yleisöä.',
        },
        callToAction: {
          fieldType: 'internalButtonLink',
          content: 'Lue lisää',
          href: '/en/blog3',
        },
        blockType: 'defaultBlock',
        blockName: 'toinen blogi',
      },



      {
        media: {
          fieldType: 'image',
          image: {
            id: 'b0add141-68fa-5c3e-9f42-52bc8c3a4443',
            type: 'imageAsset',
            attributes: {
              variants: {
                square400: {
                  height: 400,
                  width: 400,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/04/130f247675dcc653dd52b20a2217fcd137eaaf?auto=format&crop=edges&fit=crop&h=400&w=400&s=4d8342f80d686469c897d7f24a8849e0',
                  name: 'square400',
                },
                square800: {
                  height: 800,
                  width: 800,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/04/130f247675dcc653dd52b20a2217fcd137eaaf?auto=format&crop=edges&fit=crop&h=800&w=800&s=ba196f81db32f2ea6efec2d232992fe4',
                  name: 'square800',
                },
                square1200: {
                  height: 1200,
                  width: 1200,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/04/130f247675dcc653dd52b20a2217fcd137eaaf?auto=format&crop=edges&fit=crop&h=1200&w=1200&s=cae5b1846a412801f99191171735492d',
                  name: 'square1200',
                },
                square2400: {
                  height: 2400,
                  width: 2400,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/04/130f247675dcc653dd52b20a2217fcd137eaaf?auto=format&crop=edges&fit=crop&h=2400&w=2400&s=df10e0322d6f49ffa104e7944a9ec76a',
                  name: 'square2400',
                },
              },
              assetPath:
                '/content/pages/landing-page/block-media-018cee3a-d618-818e-bb03-ebb1b850a9bd.jpg',
            },
          },
          aspectRatio: '1/1',
          alt: 'Sport',
        },
        title: {
          fieldType: 'heading3',
          content: 'Sponsorointi: Avain menestykseen?',
        },
        text: {
          fieldType: 'markdown',
          content:
            'Sponsorointi tarjoaa sekä seuroille että yksittäisille urheilijoille monenlaisia mahdollisuuksia. Yksi tärkeimmistä syistä näiden sopimusten tekoon on taloudellinen tuki.',
        },
        callToAction: {
          fieldType: 'internalButtonLink',
          content: 'Lue lisää',
          href: '/en/blog2',
        },
        blockType: 'defaultBlock',
        blockName: 'toinen blogi',
      },




    ],
    appearance: {
      fieldType: 'defaultAppearance',
    },
  },
];

export const sectionfiLogin = [
  {
    description: {
      fieldType: 'paragraph',
      content:
        'Are you a brand or an athlete seeking sport sponsorship opportunities? Discover sbonssy, the innovative marketplace connecting you with sponsorship deals instantly.',
      // "content": "Oletko brändi tai urheilija, joka etsii urheilun sponsorointimahdollisuuksia? \nLöydä sbonssy, innovatiivinen markkinapaikka, joka yhdistää sinut sponsoroinnin\ntarjouksiin välittömästi."
    },
    numColumns: 2,
    callToAction: {
      fieldType: 'none',
      content: 'Kirjaudu',
      href: '',
    },
    sectionType: 'hero',
    sectionId: '',
    title: {
      fieldType: 'heading1',
      content: 'Link between \nbrands and athletes',
    },
    sectionName: 'Johdatus markkinoille',
    blocks: [],
    appearance: {
      fieldType: 'customAppearance',
      backgroundImage: {
        id: 'f3b0b4c4-37cc-5844-9500-d3cce7c9b775',
        type: 'imageAsset',
        attributes: {
          variants: {
            scaled800: {
              height: 533,
              width: 800,
              url:
                'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/f6/9843221111994f703781ac8d0d3be12ff53d4a?auto=format&fit=clip&h=800&w=800&s=b880d0ba2c0dfdca2b18226247cca8fe',
              name: 'scaled800',
            },
            scaled1200: {
              height: 800,
              width: 1200,
              url:
                'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/f6/9843221111994f703781ac8d0d3be12ff53d4a?auto=format&fit=clip&h=1200&w=1200&s=740b37a8ab6c33640ce0a28ef7390401',
              name: 'scaled1200',
            },
            scaled2400: {
              height: 1600,
              width: 2400,
              url:
                'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/f6/9843221111994f703781ac8d0d3be12ff53d4a?auto=format&fit=clip&h=2400&w=2400&s=2d6a7add46a2f21938493bf84b067b12',
              name: 'scaled2400',
            },
          },
          assetPath:
            '/content/pages/landing-page/section-background-018e5011-03bb-88f5-a8e3-05b3a57f29a8.jpg',
        },
      },
      textColor: 'white',
      backgroundColor: '#97b1c9',
      backgroundImageOverlay: {
        preset: 'none',
      },
    },
  },
  {
    title: {
      fieldType: 'heading2',
    },
    description: {
      fieldType: 'paragraph',
    },
    callToAction: {
      fieldType: 'none',
    },
    appearance: {
      fieldType: 'defaultAppearance',
    },
    sectionName: 'athlete team',
    sectionType: 'columns',
    numColumns: 2,
    blocks: [],
  },
  {
    description: {
      fieldType: 'paragraph',
      content:
        '  Discover exciting opportunities to enhance your sports career or brand. Sign up and give a significant boost to your success in the sports industry.',
    },
    numColumns: 3,
    callToAction: {
      fieldType: 'none',
    },
    sectionType: 'carousel',
    sectionId: '',
    title: {
      fieldType: 'heading2',
      content: 'Sponsorship opportunities',
    },
    sectionName: 'Suositellut paikat',
    blocks: [
      {
        media: {
          fieldType: 'image',
          image: {
            id: 'e09e2973-b20e-5522-a6f4-3901b03e87b1',
            type: 'imageAsset',
            attributes: {
              variants: {
                square400: {
                  height: 400,
                  width: 400,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/a4/a9cd540c4072603b007cb83ecf9c79fa41a409?auto=format&crop=edges&fit=crop&h=400&w=400&s=ebcf4117583f40809e4bd28368b23b20',
                  name: 'square400',
                },
                square800: {
                  height: 800,
                  width: 800,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/a4/a9cd540c4072603b007cb83ecf9c79fa41a409?auto=format&crop=edges&fit=crop&h=800&w=800&s=d07250b7c829511c0ccd2a9432c72ac8',
                  name: 'square800',
                },
                square1200: {
                  height: 1200,
                  width: 1200,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/a4/a9cd540c4072603b007cb83ecf9c79fa41a409?auto=format&crop=edges&fit=crop&h=1200&w=1200&s=aa68d3c4bafcb08b73683299ea1c0c93',
                  name: 'square1200',
                },
                square2400: {
                  height: 2400,
                  width: 2400,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/a4/a9cd540c4072603b007cb83ecf9c79fa41a409?auto=format&crop=edges&fit=crop&h=2400&w=2400&s=03feb4394fb383d852811e9c3640879d',
                  name: 'square2400',
                },
              },
              assetPath:
                '/content/pages/landing-page/block-media-018e5014-faea-827a-8ba6-2119d6860bcf.png',
            },
          },
          aspectRatio: '1/1',
          alt: 'Logo',
        },
        title: {
          fieldType: 'heading3',
          content: 'Logo visibility',
        },
        text: {
          fieldType: 'markdown',
          content: 'Brand logo on a jersey or equipment?\n\n\n\n',
        },
        callToAction: {
          fieldType: 'none',
          href: '',
        },
        blockType: 'defaultBlock',
        blockId: '',
        blockName: 'Tyyppi - Logo',
      },
      {
        media: {
          fieldType: 'image',
          image: {
            id: '32a44158-ee29-52d0-af45-3050d556eda8',
            type: 'imageAsset',
            attributes: {
              variants: {
                square400: {
                  height: 400,
                  width: 400,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/2b/3fcbbd6e997219982e833ee87b152fd0bfc6ee?auto=format&crop=edges&fit=crop&h=400&w=400&s=86696072f7354c238cd8507e253fe98e',
                  name: 'square400',
                },
                square800: {
                  height: 800,
                  width: 800,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/2b/3fcbbd6e997219982e833ee87b152fd0bfc6ee?auto=format&crop=edges&fit=crop&h=800&w=800&s=6b750e86e2dffe0bff41f5eaf0e3a57b',
                  name: 'square800',
                },
                square1200: {
                  height: 1200,
                  width: 1200,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/2b/3fcbbd6e997219982e833ee87b152fd0bfc6ee?auto=format&crop=edges&fit=crop&h=1200&w=1200&s=3488c94e35f3e8a8e9e589ca21141331',
                  name: 'square1200',
                },
                square2400: {
                  height: 2400,
                  width: 2400,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/2b/3fcbbd6e997219982e833ee87b152fd0bfc6ee?auto=format&crop=edges&fit=crop&h=2400&w=2400&s=dde35c5f1d161d669502ae7ebfa15238',
                  name: 'square2400',
                },
              },
              assetPath:
                '/content/pages/landing-page/block-media-018e5018-5374-8c2a-b441-cc7af4bf4034.png',
            },
          },
          aspectRatio: '1/1',
          alt: 'Social media',
        },
        title: {
          fieldType: 'heading3',
          content: 'Social media',
        },
        text: {
          fieldType: 'markdown',
          content: 'Authentic user-generated content to increase brand awareness!\n\n',
        },
        callToAction: {
          fieldType: 'none',
        },
        blockType: 'defaultBlock',
        blockId: '',
        blockName: 'Tyyppi - Sosiaalinen',
      },
      {
        media: {
          fieldType: 'image',
          image: {
            id: '46e2f66f-5b99-5ea1-b9c4-a732ade6d8f3',
            type: 'imageAsset',
            attributes: {
              variants: {
                square400: {
                  height: 400,
                  width: 400,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/ea/e3616419a64f9cc815934ae7c80fd35cdc7e52?auto=format&crop=edges&fit=crop&h=400&w=400&s=f6ef5f1ad4a6ad0dfcc0ae66eed26409',
                  name: 'square400',
                },
                square800: {
                  height: 800,
                  width: 800,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/ea/e3616419a64f9cc815934ae7c80fd35cdc7e52?auto=format&crop=edges&fit=crop&h=800&w=800&s=ddb643e716fd995e9a58188a81adbcdf',
                  name: 'square800',
                },
                square1200: {
                  height: 1200,
                  width: 1200,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/ea/e3616419a64f9cc815934ae7c80fd35cdc7e52?auto=format&crop=edges&fit=crop&h=1200&w=1200&s=0b367144449f67e544fef57a23b5a790',
                  name: 'square1200',
                },
                square2400: {
                  height: 2400,
                  width: 2400,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/ea/e3616419a64f9cc815934ae7c80fd35cdc7e52?auto=format&crop=edges&fit=crop&h=2400&w=2400&s=c043740ff09b34e31876484f45c8137d',
                  name: 'square2400',
                },
              },
              assetPath:
                '/content/pages/landing-page/block-media-018e5018-71fb-88db-94cd-f81b2f5e68f6.png',
            },
          },
          alt: 'Appearance',
          aspectRatio: '1/1',
        },
        title: {
          fieldType: 'heading3',
          content: 'Appearance',
        },
        text: {
          fieldType: 'markdown',
          content: 'Invite athlete to a corporate event or organize photo shoots to highlight your business.\n\n',
        },
        callToAction: {
          fieldType: 'none',
        },
        blockType: 'defaultBlock',
        blockId: '',
        blockName: 'Tyyppi - Ulkonäkö',
      },
      {
        media: {
          fieldType: 'image',
          image: {
            id: 'f08cf6e4-a8f2-5be3-8307-cd4fa17ac6d6',
            type: 'imageAsset',
            attributes: {
              variants: {
                square400: {
                  height: 400,
                  width: 400,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/40/929b036cfa0bb795d0ed700bf87575b9d341eb?auto=format&crop=edges&fit=crop&h=400&w=400&s=64011d03fb7ffb9261bfeb4d1ee4eba8',
                  name: 'square400',
                },
                square800: {
                  height: 800,
                  width: 800,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/40/929b036cfa0bb795d0ed700bf87575b9d341eb?auto=format&crop=edges&fit=crop&h=800&w=800&s=5934ac8ea262739a1db81774de95810e',
                  name: 'square800',
                },
                square1200: {
                  height: 1200,
                  width: 1200,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/40/929b036cfa0bb795d0ed700bf87575b9d341eb?auto=format&crop=edges&fit=crop&h=1200&w=1200&s=e6665bf2b370742c9548c2de5cd2db20',
                  name: 'square1200',
                },
                square2400: {
                  height: 2400,
                  width: 2400,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/40/929b036cfa0bb795d0ed700bf87575b9d341eb?auto=format&crop=edges&fit=crop&h=2400&w=2400&s=52a0c669fbc24a61031ecf7722350bb8',
                  name: 'square2400',
                },
              },
              assetPath:
                '/content/pages/landing-page/block-media-018e5018-9a17-8455-97fc-f7e95287d5eb.png',
            },
          },
          aspectRatio: '1/1',
          alt: 'Brand ambassador',
        },
        title: {
          fieldType: 'heading3',
          content: 'Brand ambassador',
        },
        text: {
          fieldType: 'markdown',
          content: 'Build your brand with long-term and multi-channel partnerships.\n\n',
        },
        callToAction: {
          fieldType: 'none',
        },
        blockType: 'defaultBlock',
        blockId: '',
        blockName: 'Tyyppi - Brand Ambassador',
      },
      {
        media: {
          fieldType: 'image',
          image: {
            id: '6a4b882f-8322-5eda-b98b-b4fde1bd2947',
            type: 'imageAsset',
            attributes: {
              variants: {
                square400: {
                  height: 400,
                  width: 400,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/93/63b4c4330777052f754180228dd49f85f2106c?auto=format&crop=edges&fit=crop&h=400&w=400&s=12fb0c38ed09e5829cc41b51d3aa89f0',
                  name: 'square400',
                },
                square800: {
                  height: 800,
                  width: 800,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/93/63b4c4330777052f754180228dd49f85f2106c?auto=format&crop=edges&fit=crop&h=800&w=800&s=37916ce96270704279e79ce0c86c7f3d',
                  name: 'square800',
                },
                square1200: {
                  height: 1200,
                  width: 1200,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/93/63b4c4330777052f754180228dd49f85f2106c?auto=format&crop=edges&fit=crop&h=1200&w=1200&s=7032adff46772683ef046f075cc86a0f',
                  name: 'square1200',
                },
                square2400: {
                  height: 2400,
                  width: 2400,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/93/63b4c4330777052f754180228dd49f85f2106c?auto=format&crop=edges&fit=crop&h=2400&w=2400&s=7f7c8190c247a0e7ab52a9c729ef02a9',
                  name: 'square2400',
                },
              },
              assetPath:
                '/content/pages/landing-page/block-media-018e5019-21c8-8ec2-8891-159fb7c65746.png',
            },
          },
          aspectRatio: '1/1',
          alt: 'Event',
        },
        title: {
          fieldType: 'heading3',
          content: 'Event',
        },
        text: {
          fieldType: 'markdown',
          content: 'Find an event and reach your target audience effectively.\n\n',
        },
        callToAction: {
          fieldType: 'none',
        },
        blockType: 'defaultBlock',
        blockName: 'Tyypillinen Event',
      },
    ],
    appearance: {
      fieldType: 'defaultAppearance',
    },
  },
  {
    description: {
      fieldType: 'paragraph',
      content:
        'Our platform offers an easy way finding partnerships and signing sports sponsorship deals. \nWe believe that the best results will be achieved when resources are focused core \ncompetences. So let us help you.',
    },
    numColumns: 3,
    callToAction: {
      fieldType: 'none',
      href: '/s',
      content: 'Testata',
    },
    sectionType: 'columns',
    sectionId: '',
    title: {
      fieldType: 'heading2',
      content: 'How sbonssy works',
    },
    sectionName: 'Kuinka se toimii',
    blocks: [
      {
        media: {
          fieldType: 'none',
        },
        title: {
          fieldType: 'heading3',
          content: 'Create campaign',
        },
        text: {
          fieldType: 'markdown',
          content:
            'Rakenna itsellesi\nbrändille sopiva kampanja ja hanki ehdotuksia\nsuoraan urheilijoilta',
        },
        callToAction: {
          fieldType: 'none',
          content: 'Kirjaudu',
          href: '/en/signup',
        },
        blockType: 'defaultBlock',
        blockId: '',
        blockName: 'Uusi kampanja',
      },
      {
        media: {
          fieldType: 'none',
        },
        title: {
          fieldType: 'heading3',
          content: 'Tutustu urheilijoihin',
        },
        text: {
          fieldType: 'markdown',
          content:
            'Rekisteröidy ja luo tili, se on ilmaista.\nEtsitkö urheilijaa tai kenties joukkuetta\ntukea? Sinä päätät, mikä se on\nparas valinta brändillesi.',
        },
        callToAction: {
          fieldType: 'none',
          content: 'Kirjaudu',
          href: '/en/signup',
        },
        blockType: 'defaultBlock',
        blockId: '',
        blockName: 'Ota selvää urheilijoiden tarjoamista sponsorointimahdollisuuksista',
      },
      {
        media: {
          fieldType: 'none',
        },
        title: {
          fieldType: 'heading3',
          content: 'Sponsorointi on tehty helpoksi',
        },
        text: {
          fieldType: 'markdown',
          content:
            'Lähetä ehdotuksia sinua kiinnostaville brändeille tai vastaanota tarjouksia suoraan yrityksiltä. Sponsorin etsiminen, neuvotteleminen ja sopimuksen allekirjoittaminen on kanssamme helppoa.',
        },
        callToAction: {
          fieldType: 'none',
          content: 'Kirjaudu',
          href: '/en/signup',
        },
        blockType: 'defaultBlock',
        blockName: 'Sponsorointi on tehty helpoksi',
      },
    ],
    appearance: {
      fieldType: 'defaultAppearance',
    },
  },
  {
    description: {
      fieldType: 'paragraph',
    },
    numColumns: 3,
    callToAction: {
      fieldType: 'none',
    },
    sectionType: 'columns',
    sectionId: 'blog',
    title: {
      fieldType: 'heading2',
      content: 'Blogit',
    },
    sectionName: 'Blogi',
    blocks: [
      {
        media: {
          fieldType: 'image',
          image: {
            id: 'b0add141-68fa-5c3e-9f42-52bc8c3a4443',
            type: 'imageAsset',
            attributes: {
              variants: {
                square400: {
                  height: 400,
                  width: 400,
                  url: "https://sharetribe-assets.imgix.net/666a959e-c7e6-49da-b986-37d4681b6574/raw/b4/720bc24169dbec286d7e28a2b62168cf582a53?auto=format&crop=edges&fit=crop&h=400&w=400&s=3ca4e41bc1c1a1a6bd188577d0760724"

                },
                square800: {
                  height: 800,
                  width: 800,
                  url:
                    "https://sharetribe-assets.imgix.net/666a959e-c7e6-49da-b986-37d4681b6574/raw/b4/720bc24169dbec286d7e28a2b62168cf582a53?auto=format&crop=edges&fit=crop&h=800&w=800&s=835b93e920f67385149f4f7f0cb21418",
                  name: 'square800',
                },
                square1200: {
                  height: 1200,
                  width: 1200,
                  url:
                    "https://sharetribe-assets.imgix.net/666a959e-c7e6-49da-b986-37d4681b6574/raw/b4/720bc24169dbec286d7e28a2b62168cf582a53?auto=format&crop=edges&fit=crop&h=1200&w=1200&s=7fe1a216bf6fda19fc34a843ecf14bd8",
                  name: 'square1200',
                },
                square2400: {
                  height: 2400,
                  width: 2400,
                  url:
                    "https://sharetribe-assets.imgix.net/666a959e-c7e6-49da-b986-37d4681b6574/raw/b4/720bc24169dbec286d7e28a2b62168cf582a53?auto=format&crop=edges&fit=crop&h=2400&w=2400&s=a7576141dddd33e2acd3b855a747a4ac",
                },
              },
              assetPath:
                "/content/pages/landing-page-fi/block-media-0191290f-98ad-851b-838e-7e597dc06456.jpg",
            },
          },
          aspectRatio: '1/1',
          alt: 'Sport',
        },
        title: {
          fieldType: 'heading3',
          content: 'Haastattelu:\n\nMika Kojonkoski',
        },
        text: {
          fieldType: 'markdown',
          content:
            'Kysyimme valmentajalegendalta mitä mietteitä herättää suomalaisen huippu-urheilun ja sponsoroinnin tulevaisuus.',
        },
        callToAction: {
          fieldType: 'internalButtonLink',
          content: 'Lue lisää',
          href: '/en/blogi-mika-kojonkoski',
        },
        blockType: 'defaultBlock',
        blockName: 'toinen blogi',
      },
      {
        media: {
          fieldType: 'image',
          image: {
            id: 'b0add141-68fa-5c3e-9f42-52bc8c3a4443',
            type: 'imageAsset',
            attributes: {
              variants: {
                square400: {
                  height: 400,
                  width: 400,
                  url:
                    'https://sharetribe-assets.imgix.net/666a959e-c7e6-49da-b986-37d4681b6574/raw/6f/7c70c8f9188013b33ff5f3a4e6fee99b4be4ad?auto=format&crop=edges&fit=crop&h=400&w=400&s=ec58268903d67e7a38b569267e90b01c',
                  name: 'square400',
                },
                square800: {
                  height: 800,
                  width: 800,
                  url:
                    "https://sharetribe-assets.imgix.net/666a959e-c7e6-49da-b986-37d4681b6574/raw/6f/7c70c8f9188013b33ff5f3a4e6fee99b4be4ad?auto=format&crop=edges&fit=crop&h=800&w=800&s=f18a05351f1d96be54c014abbd31fa5f",
                  name: 'square800',
                },
                square1200: {
                  height: 1200,
                  width: 1200,
                  url:
                    "https://sharetribe-assets.imgix.net/666a959e-c7e6-49da-b986-37d4681b6574/raw/6f/7c70c8f9188013b33ff5f3a4e6fee99b4be4ad?auto=format&crop=edges&fit=crop&h=1200&w=1200&s=45143461ce66ba055a13a56d6ce286f7",
                  name: 'square1200',
                },
                square2400: {
                  height: 2400,
                  width: 2400,
                  url:
                    "https://sharetribe-assets.imgix.net/666a959e-c7e6-49da-b986-37d4681b6574/raw/6f/7c70c8f9188013b33ff5f3a4e6fee99b4be4ad?auto=format&crop=edges&fit=crop&h=2400&w=2400&s=769f95d8b4ce719f6e7092d4f20522f9",
                  name: 'square2400',
                },
              },
              assetPath:
                "/content/pages/landing-page-fi/block-media-0190fa14-cd94-8a6b-90b3-3f8baa3a4b22.jpg",
            },
          },
          aspectRatio: '1/1',
          alt: 'Sport',
        },
        title: {
          fieldType: 'heading3',
          content: 'Monikanavaista markkinointia',
        },
        text: {
          fieldType: 'markdown',
          content:
            'Urheilu on tehokas markkinoinnin kanava, jonka avulla yritykset voi rakentaa brändin tunnettuutta, kasvattaa myyntiä sekä tavoittaa sitoutunutta yleisöä.',
        },
        callToAction: {
          fieldType: 'internalButtonLink',
          content: 'Lue lisää',
          href: '/en/blog3',
        },
        blockType: 'defaultBlock',
        blockName: 'toinen blogi',
      },
      {
        media: {
          fieldType: 'image',
          image: {
            id: 'b0add141-68fa-5c3e-9f42-52bc8c3a4443',
            type: 'imageAsset',
            attributes: {
              variants: {
                square400: {
                  height: 400,
                  width: 400,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/04/130f247675dcc653dd52b20a2217fcd137eaaf?auto=format&crop=edges&fit=crop&h=400&w=400&s=4d8342f80d686469c897d7f24a8849e0',
                  name: 'square400',
                },
                square800: {
                  height: 800,
                  width: 800,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/04/130f247675dcc653dd52b20a2217fcd137eaaf?auto=format&crop=edges&fit=crop&h=800&w=800&s=ba196f81db32f2ea6efec2d232992fe4',
                  name: 'square800',
                },
                square1200: {
                  height: 1200,
                  width: 1200,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/04/130f247675dcc653dd52b20a2217fcd137eaaf?auto=format&crop=edges&fit=crop&h=1200&w=1200&s=cae5b1846a412801f99191171735492d',
                  name: 'square1200',
                },
                square2400: {
                  height: 2400,
                  width: 2400,
                  url:
                    'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/04/130f247675dcc653dd52b20a2217fcd137eaaf?auto=format&crop=edges&fit=crop&h=2400&w=2400&s=df10e0322d6f49ffa104e7944a9ec76a',
                  name: 'square2400',
                },
              },
              assetPath:
                '/content/pages/landing-page/block-media-018cee3a-d618-818e-bb03-ebb1b850a9bd.jpg',
            },
          },
          aspectRatio: '1/1',
          alt: 'Sport',
        },
        title: {
          fieldType: 'heading3',
          content: 'Sponsorointi: Avain menestykseen?',
        },
        text: {
          fieldType: 'markdown',
          content:
            'Sponsorointi tarjoaa sekä seuroille että yksittäisille urheilijoille monenlaisia mahdollisuuksia. Yksi tärkeimmistä syistä näiden sopimusten tekoon on taloudellinen tuki.',
        },
        callToAction: {
          fieldType: 'heading2',
          content: 'Lue lisää',
          href: '/en/blog2',
        },
        blockType: 'defaultBlock',
        blockName: 'toinen blogi',
      },

    ],

  },
];

